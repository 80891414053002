import React, { useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

//CSS
import "./ChapterDetailComp.css";
import "bootstrap/dist/css/bootstrap.min.css";

//Components
import Chapter from "../Chapter/Chapter";

//Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function ChapterDetailComp({ charFound }) {
  const bgImage = charFound.url;

  return (
    <>
      <Row>
        <Col className="chapters-text" lg={6}>
          <div className="scroll-gradient"></div>
          <Col className="text-wrapper">
            <Chapter charFound={charFound} />
          </Col>
        </Col>
        <Col
          className="chapters-img"
          style={{ backgroundImage: `url(${bgImage})` }}
          lg={6}></Col>
      </Row>
    </>
  );
}

export default ChapterDetailComp;
