import { Link } from "react-router-dom";

import "./Hero.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

//Json
import text from "../../text/text.json";

function Hero() {
  const navChars = text.char;
  const navFound = navChars.find((item) => item.id === "sobre-los-autores");

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            className="hero-text d-flex flex-column justify-content-between"
            lg="6"
          >
            <Col>
              <h1>
                Navegar <br></br>el <span>Colapso</span>
              </h1>
              <p className="mb-md-5 md-4">
                Una guía para enfrentar la crisis civilizatoria y las falsas
                soluciones al cambio climático. Descarga la{" "}
                <a
                  target="_blank"
                  href="https://mx.boell.org/sites/default/files/2023-11/web_navegar-el-colapso_compressed.pdf"
                >
                  guía completa aquí
                </a>
              </p>
              <Col className="row">
                <Link className="col-lg-6" to="/capitulos">
                  <Button
                    variant="dark"
                    className="py-md-3 py-3 px-lg-2 px-3 col-12"
                  >
                    Ir a Capitulos
                  </Button>
                </Link>
                <a className="col-lg-6" href={navFound.id}>
                  <Button
                    variant="link"
                    className="py-md-3 py-3 px-lg-2 px-3 col-12"
                  >
                    Acerca de los autores
                  </Button>
                </a>
              </Col>
            </Col>
            <Col className="d-flex logos">
              <div className="col-4 d-flex justify-content-end">
                <a target="_blank" href="https://mx.boell.org/es">
                  <img className="img-fluid" src="/assets/logo-boell.svg" />
                </a>
              </div>
              <div className="col-3 d-flex">
                <a target="_blank" href="https://conexionesclimaticas.org/">
                  <img className="img-fluid" src="/assets/logo-cc.svg" />
                </a>
              </div>
              <div className="col-3 d-flex bajo">
                <a target="_blank" href="https://bajotierraediciones.com/">
                  <img className="img-fluid" src="/assets/logo-bajo.png" />
                </a>
              </div>
            </Col>
          </Col>
          <Col className="hero-img" lg="6"></Col>
        </Row>
      </Container>
    </>
  );
}

export default Hero;
